define("discourse/plugins/discourse-blizzard-plugin/initializers/customize-admin-widgets", ["exports", "rsvp", "discourse/lib/plugin-api", "discourse/models/composer", "discourse/models/topic", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/blizzard-add-user-note"], function (_exports, _rsvp, _pluginApi, _composer, _topic, _decorators, _I18n, _blizzardAddUserNote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "customize-admin-widgets",
    initialize(container) {
      const modal = container.lookup("service:modal");
      (0, _pluginApi.withPluginApi)("0.8.17", api => {
        var _dec, _obj;
        const {
          h
        } = api;
        api.modifyClass("model:admin-user", {
          pluginId: "blizzard",
          unsuspend() {
            const doUnsuspend = this._super;
            return this.addNote(() => {
              return doUnsuspend.call(this);
            });
          },
          unsilence() {
            const doUnsilence = this._super;
            return this.addNote(() => {
              return doUnsilence.call(this);
            });
          },
          addNote(callback) {
            const user = this;
            modal.show(_blizzardAddUserNote.default, {
              model: {
                user,
                callback
              }
            });
            return _rsvp.Promise.resolve(this);
          }
        });
        api.modifyClass("component:reviewable-item", {
          pluginId: "blizzard",
          clientEdit(reviewable, performAction) {
            this.store.find("post", reviewable.post_id).then(p => {
              _topic.default.find(p.topic_id, {}).then(t_json => {
                const t = _topic.default.create(t_json);
                p.set("topic", t);
                if (!this.currentUser) {
                  const dialog = container.lookup("service:dialog");
                  return dialog.alert(_I18n.default.t("post.controls.edit_anonymous"));
                } else if (!p.can_edit) {
                  return false;
                }
                const opts = {
                  post: p,
                  action: _composer.default.EDIT,
                  draftKey: p.get("topic.draft_key"),
                  draftSequence: p.get("topic.draft_sequence"),
                  skipDraftCheck: true,
                  skipJumpOnSave: true
                };
                const composer = container.lookup("controller:composer");
                composer.open(opts);
                return performAction();
              });
            });
            return;
          }
        });

        // Staff can view additional information on a user's posts
        api.decorateWidget("poster-name:after", helper => {
          let result = [];
          let ucf = helper.attrs.userCustomFields;
          if (ucf) {
            if (ucf.battletag) {
              result.push(h("div.battle-tag", ucf.battletag));
            }
            if (ucf.bnet_id) {
              result.push(h("div.bnet-id", ucf.bnet_id));
            }
          }
          if (helper.attrs.created_ip_address) {
            result.push(h("div.created-ip-address", {}, [h("a", {
              href: "https://geoip.battle.net/lookup/" + encodeURI(helper.attrs.created_ip_address),
              target: "_blank"
            }, [String(helper.attrs.created_ip_address)])]));
          }
          if (result.length) {
            return h("div.staff-info", result);
          }
        });

        // Update custom penalty reasons when suspending/silencing
        let updatePenaltyReasons = (_dec = (0, _decorators.default)("reasonKeys"), (_obj = {
          pluginId: "blizzard",
          reasons(keys) {
            return keys.map(key => {
              return {
                id: key,
                name: _I18n.default.t(`admin.${key}`)
              };
            });
          },
          setReason() {
            if (this.isCustomReason) {
              this.set("reason", this.customReason);
            } else {
              this.set("reason", this.selectedReason);
            }
          },
          didInsertElement() {
            this._super();
            if (this.penaltyType === "silence") {
              this.set("reasonKeys", ["common.cms.report.ADVERTISING", "common.cms.report.HARASSMENT", "common.cms.report.INAPPROPRIATE", "common.cms.report.REAL_LIFE_THREATS", "common.cms.report.SPAMMING", "common.cms.report.TROLLING", "common.cms.report.OTHER"]);
            } else {
              this.set("reasonKeys", ["common.cms.report.SPAMMING", "common.cms.report.SPAMMING_TROLLING", "common.cms.report.REAL_LIFE_THREATS", "common.cms.report.ILLEGAL", "common.cms.report.ADVERTISING", "common.cms.report.ADVERTISING_STRADING", "common.cms.report.RACIAL_HATRED", "common.cms.report.RELIGION_HATRED", "common.cms.report.IN_GAME", "common.cms.report.OBSCENE_LANGUAGE", "common.cms.report.HARASSMENT", "common.cms.report.OTHER", "common.cms.report.NOT_SPECIFIED", "common.cms.report.TROLLING"]);
            }
            this.set("selectedReason", "common.cms.report.OTHER");
            this.setReason();
          }
        }, (_applyDecoratedDescriptor(_obj, "reasons", [_dec], Object.getOwnPropertyDescriptor(_obj, "reasons"), _obj)), _obj));
        api.modifyClass("component:admin-penalty-reason", updatePenaltyReasons);
      });
    }
  };
});